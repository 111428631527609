import React from "react";
import get from "lodash/get";
import { array, string } from "prop-types";
import { connect } from "react-redux";
import TopHeader from "./top-header";
import MenuBar from "./menu-bar";
//import { WeatherWidget } from "./weather-widget";
import useUrl from "../../hook/useUrl";
import "./header.m.css";
import ErrorMessage from "../atoms/error-message";

// Common wrapper for navigation. We could add OffcanvasMenu, Navbar etc components here.
const HeaderBase=(props)=> {
  const [toHideNative] = useUrl();
    return (
      <div styleName={!toHideNative?"header-wrapper":"hide"}>
         <TopHeader pageType={props.pageType} topHeaderLinks={props.topHeaderLinks}/>
         <div styleName="error-small-mobile-fix">
         <ErrorMessage />
         </div>
         <MenuBar mainNav={props.mainNav} menuItems={props.menu} hamburgerMenu= {props.hamburgerMenu} default={props.default} pageType={props.pageType} currentPath={props.currentPath}/>
         {/*<div styleName="weather-mobile">
          <WeatherWidget/>
         </div>*/}
      </div>
    );
  }

function mapStateToProps(state) {
  //console.log("from HeaderBase mapStateToProps ", state)
  return {
    menu: get(state, ["qt", "data", "navigationMenu", "menuItems"], []),
    pageType: get(state, ["qt", "config", "page-type"], ""),
    default: get(state, ["qt", "data", "navigationMenu","default"]),
    hamburgerMenu: get(state, ["qt", "data", "navigationMenu","hamburgerMenu"]),
    topHeaderLinks: get(state, ["qt", "data", "navigationMenu", "topNavMenuLinks"], []),
    mainNav: get(state, ["qt", "data", "navigationMenu", "mainNav"], []),
    currentPath: get(state,["qt", "currentPath"])
  };
}

HeaderBase.propTypes = {
  menu: array,
  mainNav: array,
  hamburgerMenu:array,
  default:array,
  pageType: string
};

export const Header = connect(mapStateToProps, () => ({}))(HeaderBase);
